<template>
    <div class="payment-detail">
        <layout :header="$t('PURCHASE')" @goback="$router.push('/store/' + $route.params.id)" :bodyStrong="false">
            <template v-slot:content>
                <v-col cols="12" class="pa-0" xs="12" sm="10" md="8" lg="6">
                    <v-row dense class="px-1 payment-detail-body">
                        <v-col cols="12">
                            <v-img :src="couponById.image_url" :aspect-ratio="3/2" cover position="center"
                                class="image-ban">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="#463729"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-col>
                        <v-col cols="12" class="mt-3 px-1">
                            <div>{{$i18n.locale == 'th' ? couponById.name: couponById.name_en}}</div>
                            <div>
                                <h1>{{formatMoney(couponById.price)}} {{$i18n.locale == 'th' ? 'บาท': 'THB'}}</h1>
                            </div>
                        </v-col>
                        <v-col cols="8" class="mt-5 px-1">{{$i18n.locale == 'th' ? couponById.name: couponById.name_en}}</v-col>
                        <v-col cols="4" class="text-right mt-5 px-1 pri-color">{{formatMoney((couponById.price * 0.93).toFixed(2))}}
                        </v-col>
                        <v-col cols="8" class="px-1 li-h-1">VAT 7%</v-col>
                        <v-col cols="4" class="text-right px-1 pri-color li-h-1">
                            {{formatMoney((couponById.price * 0.07).toFixed(2))}}</v-col>
                        <v-col cols="6" class="mt-5 px-1">{{$i18n.locale == 'th' ? 'รวมทั้งหมด': 'Total'}}</v-col>
                        <v-col cols="6" class="text-right mt-5 px-1">{{formatMoney(couponById.price)}}</v-col>
                        <v-col cols="12" class="text-center mt-5 px-1 pri-color" v-if="couponById.point > 0 || couponById.point != undefined">{{$i18n.locale == 'th' ? 'คุณได้รับ': 'You earn'}} +{{couponById.point}} {{$i18n.locale == 'th' ? 'คะแนน': 'points'}}</v-col>
                        <!-- <v-col cols="12" class="mt-5">
                            <v-btn block height="50px" color="#463729" @click="$router.push('/PaymentConfirm/' + $route.params.id)">
                                {{$i18n.locale == 'th' ? 'ชำระด้วย CHILPAY': 'PAY by ChilPAY'}}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="mt-1">
                            <v-btn block height="50px" color="#9D662C" @click="$router.push('/PaymentKbank/' + $route.params.id)">
                                {{$i18n.locale == 'th' ? 'ชำระด้วย KBank': 'PAY by KBank'}}
                            </v-btn>
                        </v-col> -->
                    </v-row>
                </v-col>
            </template>
        </layout>
        <v-btn class="bottom-button" block height="50px" @click="$router.push('/PaymentConfirm/' + $route.params.id)">
            {{$t('CHECKOUT')}}</v-btn>
    </div>
</template>

<script>
    import Layout from "../../components/layout_1";
    export default {
        name: "PaymentDetail",
        components: {
            layout: Layout,
        },
        computed: {
            couponById() {
                return this.$store.getters.couponById(this.$route.params.id);
            },
        },
    };
</script>

<style lang="scss">
    @import "@/assets/scss/color.scss";

    .payment-detail {
        height: 100%;

        .image-ban {
            border-radius: 12px;
        }

        .payment-detail-body {
            font-weight: 800;
            font-size: 15px;
        }

        .pri-color {
            color: $primary-color;
        }

        .li-h-1 {
            line-height: 1;
        }

        .bottom-button {
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: $primary-color !important;
            border-radius: 0px !important;
        }
    }
</style>